<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        disable-loading-on-refresh
        @refresh="getItems"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage"/>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refBookingtimesListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')">
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
                size="sm"
                @click="acceptRequest(data.item.id)"
            >
              {{ $t('Accept') }}
            </b-button>
            <b-button
                v-on:click="showModal(data.item.id)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                type="submit"
                size="sm"
            >
              {{ $t('Decline') }}
            </b-button>


          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />
    </b-card-actions>
    <!--Cancellation component-->
    <common-modal :showModal="showCancelModal" @closeModal="closeModal" :OnCloseBackDrop="false">
        <template v-slot:title>
            {{ $t('Cancel Booking') }}
        </template>
        <template v-slot:icon>
          <div class="d-flex justify-content-center">
                <alert-circle-icon size="4x" class="text-danger"></alert-circle-icon>
            </div>
        </template>
        <template v-slot:content>
          <div class="mt-2 mx-1">
              <h4>What is the reason for your Cancellation?</h4>
              <label class="mt-1">Give a quick explanation</label>
              <b-form-textarea
                v-model="message"
                placeholder="Explanation of Canceling the Event"/>
              <label>This message will be shown to the user</label>
              <b-form-checkbox
                  id="terms-and-condition"
                  v-model="agree"
                  name="terms_and_condition"
                  class="mt-1"
                  >
                    {{ $t('I have read the conditional Terms and I agree to it') }}
              </b-form-checkbox>
          </div>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-content-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      type="submit"
                      size="sm"
                      :disabled="!agree"
                      @click="submit"
                    >
                    {{ $t('Cancel Event') }}
                    </b-button>
                </div>
        </template>
      </common-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import CommonModal from '../../commonmodal/CommonModal.vue';
import { AlertCircleIcon } from 'vue-feather-icons'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import BCardActions from "@core/components/b-card-actions/BCardActions";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BFormCheckbox,
    BFormTextarea,
    TableHeader,
    TableFooter,
    BCardActions,

    CommonModal,
    AlertCircleIcon,

    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {
          key: 'event.name',
          sortable: true,
          label: this.$i18n.t('Event Name')
        },
        {
          key: 'customer.name',
          sortable: true,
          label: this.$i18n.t('Employee Name')
        },
        {
          key: 'client_name',
          sortable: true,
          label: this.$i18n.t('Company Of Employee'),
          formatter: this.formatName,
        },
        {
          key: 'event.start_date',
          sortable: true,
          label: this.$i18n.t('Original Event Date'),
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {
          key: 'event.start_time',
          sortable: true,
          label: this.$i18n.t('Start Time'),
        },
        {
          key: 'request_date',
          sortable: true,
          label:this.$i18n.t('New requested Date'),
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {
          key: 'requested_time',
          sortable: true,
          label: this.$i18n.t('New requested Time'),
          formatter: this.formatRequestTime,
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions'),
          class: 'table-action-buttons'
        }
      ],
      items: [],
      showCancelModal:false,
      cancelId:0,
      agree:false,
      message:""
    }
  },
  computed: {},
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/experts/bookings/request`)
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/experts/bookings/past/${id}`,
      )
    },

    getWeekDayName(value, key, item) {
      let weekday = [
        {
          value: 1,
          text: 'Sunday'
        },
        {
          value: 2,
          text: 'Monday'
        },
        {
          value: 3,
          text: 'Tuesday'
        },
        {
          value: 4,
          text: 'Wednesday'
        },
        {
          value: 5,
          text: 'Thursday'
        },
        {
          value: 6,
          text: 'Friday'
        },
        {
          value: 7,
          text: 'Saturday'
        },
      ]
      let weekDayName
      weekday.forEach(function (item) {
        if (item.value == value) weekDayName = item.text
      })
      return this.$t(weekDayName)
    },

    formatName(value, key, item) {
      if (item.client.firstname == null && item.client.name == null) return item.client.firm;

      if (!item.client.firstname) return item.client.name;

      return item.client.firstname + " " + item.client.name;

    },

    formatRequestTime(value, key, item) {
      return moment(String(item.request_date)).format('HH:MM')

    },

    acceptRequest(id){
      this.$http
          .put(`/experts/bookings/request/${id}`)
          .then((response) => {
            this.$bvToast.toast(this.$i18n.t("The Event request accepted"), {
              title: this.$i18n.t("Success"),
              variant: "success",
              toaster: "b-toaster-top-right",
            });
            this.showCancelModal = false;
            this.message=""
            this.agree=false
            this.getItems()
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t("The Event request not accepted"), {
              title: this.$i18n.t("Error"),
              variant: "danger",
              toaster: "b-toaster-top-right",
            });
            this.showCancelModal = false;
            this.message=""
            this.agree=false
          });
    },
    showModal(bookingId){
      this.cancelId = bookingId;
      this.showCancelModal=true;
    },
    closeModal(){
      this.showCancelModal = false;
    },
    submit(){
      this.$http
          .put(`/experts/bookings/cancel/${this.cancelId}`, {message:this.message})
          .then((response) => {
            this.$bvToast.toast(this.$i18n.t("The Event got canceled"), {
              title: this.$i18n.t("Success"),
              variant: "success",
              toaster: "b-toaster-top-right",
            });
            this.showCancelModal = false;
            this.message=""
            this.agree=false
            this.getItems()
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t("The Event not canceled"), {
              title: this.$i18n.t("Error"),
              variant: "danger",
              toaster: "b-toaster-top-right",
            });
            this.showCancelModal = false;
            this.message=""
            this.agree=false
          });
    },


  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">

</style>
